<template>
  <div class="update-scribe-job-modal">
    <section v-if="!loading">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit(createScribeJob)">
          <b-form-group>
            <!-- Title -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Title</label>
              <validation-provider
                v-slot="validationContext"
                name="title"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.title"
                  placeholder="Enter job title..."
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Case Worker -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Case Worker</label>
              <validation-provider
                v-slot="validationContext"
                name="case worker"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.case_worker"
                  placeholder="Enter case worker's name..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Resident -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Resident</label>
              <validation-provider
                v-slot="validationContext"
                name="resident"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.resident"
                  placeholder="Enter resident's name..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Resident ID -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Resident ID</label>
              <validation-provider
                v-slot="validationContext"
                name="resident ID"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="job.resident_id"
                  placeholder="Enter resident ID..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Recorded At -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Recorded At</label>
              <validation-provider
                v-slot="validationContext"
                name="recorded at"
                :rules="{ required:true, isInPast }"
              >
                <b-form-input
                  v-model="job.recorded_at"
                  placeholder="Enter recorded at date..."
                  type="datetime-local"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- File Upload -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>File</label>
              <validation-provider
                v-slot="validationContext"
                name="File"
                :rules="{ required:true, isAudioFile }"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Choose a video/audio file or drag it here..."
                  drop-placeholder="Drop file here..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>

            <!-- Job Type -->
            <label>Job Type</label>
            <b-form-select v-model="jobType" class="mb-3">
              <b-form-select-option value="General" selected>General</b-form-select-option>
              <b-form-select-option value="Keyworker">Keyworker</b-form-select-option>
              <b-form-select-option value="AssuranceAllianceActionPlan">Assurance Alliance Action Plan</b-form-select-option>
              <b-form-select-option value="SkillsPassport">Skills Passport</b-form-select-option>
              <b-form-select-option value="QAndA">Question And Answer</b-form-select-option>
              <b-form-select-option value="DST">DST</b-form-select-option>
              <b-form-select-option value="PurpleVisits">Purple Visits</b-form-select-option>
              <b-form-select-option value="HealthcareAssessment">Healthcare Assessment</b-form-select-option>
            </b-form-select>

            <b-input-group class="d-flex flex-row-reverse mt-3">
              <b-btn variant="primary" type="submit" :disabled="invalid">Create Scribe Job</b-btn>
              <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
            </b-input-group>
          </b-form-group>
        </b-form>
      </validation-observer>
    </section>
    <section v-else>
      <div class=" d-flex justify-content-center p-5">
        <b-spinner variant="primary" label="Loading devices" />
      </div>
    </section>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import PurpleScribeService from '@/services/PurpleScribeService';
import { isInPast, isAudioFile } from '@core/utils/validations/validations';

export default {
  name: 'CreateScribeJobModal',
  components: {},
  data() {
    return {
      job: {
        title: '',
        case_worker: '',
        resident: '',
        resident_id: '',
        recorded_at: '',
      },
      file: null,
      uploadVersion: 2,
      versions: {
        high_accuracy: 2,
        standard: 1
      },
      jobType: 'General',
      loading: false,
    }
  },
  computed: {
    isInPast() {
      return isInPast
    },
    isAudioFile() {
      return isAudioFile
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createScribeJob() {
      const meta = {
          case_worker_name: this.job.case_worker,
          prisoner_name: this.job.resident,
          prison_number: this.job.resident_id,
          recorded_at: this.job.recorded_at
      };
      const scribeJobDto = {
          title: this.job.title,
          meta: JSON.stringify(meta),
          version: parseInt(this.uploadVersion),
          job_type: this.jobType
      }
      try {
        this.loading = true
        const { data } = await PurpleScribeService.create(scribeJobDto)
        this.uploadVersion === this.versions.standard  && await PurpleScribeService.upload(data.id, this.file)
        this.uploadVersion === this.versions.high_accuracy  && await PurpleScribeService.uploadV2(data.id, this.file)
        helperService.showNotfySuccess(this.$toast, 'The scribe job has been successfully created.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while creating the scribe job. Please try again.')
      } finally {
        this.loading = false
        this.$emit('refresh');
      }
    },
  },
}
</script>

<style lang="scss">

</style>

